.image-gallery-slide:focus {
	outline: none;
}

.image-gallery.fullscreen-modal {
	z-index: 8000;
	background-color: rgba(0, 0, 0, 0.8);
}

.image-gallery-image {
	height: 200px;
	background-size: cover;
}

.image-gallery-content.fullscreen {
	background: none;
}

.is-wide .image-gallery-image {
	height: 200px;
	background-size: cover;
}

.big-size .image-gallery-image {
	height: 350px;
}

.big-size .image-gallery-thumbnails-wrapper.right {
	width: 270px;
	float: left;
}

.big-size .image-gallery-slides {
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
}

.is-wide .image-gallery-slides {
	border-radius: 12px;
}

.big-size  .image-gallery-slide-wrapper.right {
	width: calc(100% - 290px);
}
.big-size .image-gallery-thumbnail {
	width: 240px;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
}
.image-gallery-slide .image-gallery-image {
	object-fit: cover
}

.fullscreen-modal .image-gallery-image {
	max-height: 80vh !important;
}

.fullscreen-modal .image-gallery-image {
	height: auto;
	width: 90%;
	background-size: cover;
}

.image-gallery-icon {
	box-shadow: none;
}

.image-gallery-icon:focus {
	outline: none !important;
}

.image-gallery-svg {
	height: 60px !important;
}

.image-gallery-svg:hover {
	color: #006064;
}

.image-gallery-bullet:hover {
	background: #006064 !important;
}

.image-gallery-custom-left-nav {
	filter: drop-shadow(0 2px 2px #1a1a1a);
	cursor: pointer;
	top: 43%;
	left: 10%;
	z-index: 1;
	position: absolute;
}

.image-gallery-custom-left-nav:hover {
	color: #006064;
}

.image-gallery-custom-right-nav {
	filter: drop-shadow(0 2px 2px #1a1a1a);
	cursor: pointer;
	top: 43%;
	right: 10%;
	z-index: 1;
	position: absolute;
}

.image-gallery-custom-right-nav:hover {
	color: #006064;
}
